(function() {
	$( '#offcanvas-toggle' ).on( 'click', function( e ) {
		e.preventDefault();

		if ( $( 'body' ).hasClass( 'offcanvas-active' ) ) {
			$( 'body' ).removeClass( 'offcanvas-active' );
			setTimeout( function() {
				$( '#offcanvas' ).hide();
			}, 500 );
			$( this ).attr( 'aria-expanded', 'false' );
		} else {
			$( '#offcanvas' ).show();
			$( 'body' ).addClass( 'offcanvas-active' );
			$( this ).attr( 'aria-expanded', 'true' );
		}
	});

	$( document ).on( 'click', function( e ) {
		if ( $( 'body' ).hasClass( 'offcanvas-active' ) &&
		e.target.id != 'offcanvas' && ! $( e.target ).parents( '#offcanvas' ).length &&
		e.target.id != 'offcanvas-toggle' && ! $( e.target ).parents( '#offcanvas-toggle' ).length ) {
			$( 'body' ).removeClass( 'offcanvas-active' );
			setTimeout( function() {
				$( '#offcanvas' ).hide();
			}, 500 );
		}
	});

	$(window).on("scroll", function(e) {
		$('#gform_4 input[type="email"]').attr("autocomplete","email");
	});

	$( document ).on( 'gform_confirmation_loaded', function( e, i ) {
		$( 'a#gf_' + i ).replaceWith( '<span id="gf_' + i + '">' );

		if ( $( '#gform_confirmation_wrapper_' + i ).length ) {
			$( '#gform_confirmation_wrapper_' + i ).attr( 'role', 'alert' ).attr( 'aria-live', 'assertive' ).focus();
		}

		if ( $( '.form_saved_message_sent' ).length ) {
			$( '.form_saved_message_sent' ).attr( 'role', 'alert' ).attr( 'aria-live', 'assertive' ).focus();
		}
	} );

	$( document ).on( 'gform_post_render', function( e, i, p ) {
		$( 'a#gf_' + i ).replaceWith( '<span id="gf_' + i + '">' );

		if ( $( '#gform_wrapper_' + i + ' #error' ).length ) {
			$( '#gform_wrapper_' + i + ' #error' ).hide().show().focus();
		}

		if ( $( '#gform_submit_button_' + i ).length ) {
			$( '#gform_submit_button_' + i ).addClass( 'btn' );
		}
		

		if ( p === undefined ) {
			var $form = $( '#gform_wrapper_' + i );

			if ( ! $form.hasClass( 'focused' ) ) {
				$form.addClass( 'focused' );
				$form.find( '.form_saved_message' ).attr( 'role', 'alert' ).attr( 'aria-live', 'assertive' ).focus();
			} else {
				$form.find( '.validation_message' ).attr( 'id', 'validation_message_' + i );
				$form.find( 'input[type=email]' ).attr( 'aria-describedby', 'validation_message_' + i ).focus();
			}
		}
	} );

}());
